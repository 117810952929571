import { template as template_008496939f6d4b55a368e988e3370da8 } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { service } from "@ember/service";
import ApiSections from "./api-sections";
export default class SidebarApiPanels extends Component {
    @service
    sidebarState;
    get panelCssClass() {
        return `${this.sidebarState.currentPanel.key}-panel`;
    }
    static{
        template_008496939f6d4b55a368e988e3370da8(`
    <div class="sidebar-sections {{this.panelCssClass}}">
      <ApiSections
        @collapsable={{@collapsableSections}}
        @expandActiveSection={{this.sidebarState.currentPanel.expandActiveSection}}
        @scrollActiveLinkIntoView={{this.sidebarState.currentPanel.scrollActiveLinkIntoView}}
      />
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
