import { template as template_4d6a86e5c91a42d7bfc691ac7874cfdd } from "@ember/template-compiler";
const FKControlMenuContainer = template_4d6a86e5c91a42d7bfc691ac7874cfdd(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
