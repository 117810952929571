import { template as template_251794f33e1a4ca7971e1cc1e3a2678c } from "@ember/template-compiler";
const SidebarSectionMessage = template_251794f33e1a4ca7971e1cc1e3a2678c(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
